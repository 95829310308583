<template>
  <div>
    <b-row cols-sm="12" cols-xl="3">
      <b-col>
        <b-list-group horizontal class="mb-1">
          <b-list-group-item v-if="cP(27)" :to="{name:'brands'}" :active="$route.name.includes('brands')">Merek</b-list-group-item>
          <b-list-group-item v-if="cP(31)" :to="{name:'colors'}" :active="$route.name.includes('colors')">Warna</b-list-group-item>
          <b-list-group-item v-if="cP(35)" :to="{name:'sizes'}" :active="$route.name.includes('sizes')">Ukuran</b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import {BListGroup,BListGroupItem} from 'bootstrap-vue'

export default {
  components:{BListGroup,BListGroupItem}
}
</script>

<style>

</style>